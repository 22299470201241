




import Vue from "vue";
export default Vue.extend({
  props: {
    type: String,
    size: String,
  },
});
