







import Vue from "vue";
export default Vue.extend({
  props: {
    text: String,
    icon: String,
    type: String,
  },
});
