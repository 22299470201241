









































import Vue from "vue";
import { mapActions, mapState } from "vuex";
import Popup from "../Popup.vue";
import Input from "../Input.vue";
import Button from "../Button.vue";
import ToggleSwitch from "../ToggleSwitch.vue";
import Separator from "../Separator.vue";
import Alert from "../Alert.vue";
import Txt from "../Txt.vue";
export default {
  name: "SignupModal",
  components: {
    Popup,
    Button,
    Input,
    ToggleSwitch,
    Separator,
    Alert,
    Txt,
  },
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      code: "",
      tOu: false,
      acceptsPartnerOffers: false,
      error: null,
      signupSuccess: false,
    };
  },
  props: {
    show: Boolean,
  },
  computed: {
    ...mapState("authUser", ["_id", "token"]),
  },
  methods: {
    ...mapActions("authUser", ["setUser"]),
    open(url): void {
      open(url, "_blank");
    },
    next(): void {
      const { email, password, firstname, lastname, acceptsPartnerOffers } = this;
      this.loading = true;
      if (!this.signupSuccess) {
        Vue.$sdk.Auth.create_account(
          { email, password, firstname, lastname, accepts_partner_offers: acceptsPartnerOffers },
          {
            success: (data) => {
              this.signupSuccess = true;
              this.loading = false;
            },
            error: (e) => {
              this.error = e.message;
              this.loading = false;
            },
          }
        );
      } else {
        Vue.$sdk.Auth.validate_account(this.email, this.code, {
          success: (data) => {
            this.loading = false;
            Vue.$sdk.Auth.set_auth_user({ email, password, firstname, lastname, _id: this._id, token: this.token });
            this.$emit("signupSuccess");
            this.$emit("close");
          },
          error: (e) => {
            this.loading = false;
            this.error = e.message;
          },
        });
      }
      return;
    },
  },
};
