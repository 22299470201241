var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-step"},[_c('div',{staticClass:"bar-container"},[_c('div',{staticClass:"bar-placeholder"}),_c('div',{staticClass:"bar",style:(_vm.progressStyle)}),_c('div',{staticClass:"step",class:{
        'is-active': _vm.progessStep >= 0,
        'is-current': _vm.progessStep === 0,
      }},[_c('img',{attrs:{"src":require("../assets/img/icons/fruit.svg")}}),_vm._m(0)]),_c('div',{staticClass:"step",class:{
        'is-active': _vm.progessStep >= 1,
        'is-current': _vm.progessStep === 1,
      }},[_c('img',{attrs:{"src":require("../assets/img/icons/tech.svg")}}),_vm._m(1)]),_c('div',{staticClass:"step",class:{
        'is-active': _vm.progessStep >= 2,
        'is-current': _vm.progessStep === 2,
      }},[_c('img',{attrs:{"src":require("../assets/img/icons/wine.svg")}}),_vm._m(2)]),_c('div',{staticClass:"step",class:{
        'is-active': _vm.progessStep >= 3,
        'is-current': _vm.progessStep === 3,
      }},[_c('img',{attrs:{"src":require("../assets/img/icons/affection.svg")}}),_vm._m(3)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("En fonction")]),_c('span',[_vm._v("de vos goûts")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("Grâce à un algorithme")]),_c('span',[_vm._v("de correspondance évolué")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("Découvrez le vin")]),_c('span',[_vm._v("fait pour vous")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("Et que vous aurez plaisir")]),_c('span',[_vm._v("à goûter…")])])}]

export { render, staticRenderFns }