import { Commit } from "vuex";

type wineTypeFilter = {
  Red: boolean;
  Rose: boolean;
  White: boolean;
  Fortified: boolean;
  Effervescent: boolean;
  Sweet: boolean;
};

type winePriceFilter = {
  cheap: boolean;
  budget: boolean;
  medium: boolean;
  expensive: boolean;
};

type wineFilters = {
  type: wineTypeFilter;
  price: winePriceFilter;
  bio: boolean;
};

type FoodCollection = {
  _id?: string;
  name: string;
  food_length: number;
};

type searchStoreState = {
  tasteCollection: FoodCollection | null;
  filters: wineFilters | null;
  store: any | null;
  wines: any[] | null;
  shouldMatch: boolean;
};

export const searchStore = {
  namespaced: true,
  state: (): searchStoreState => ({
    tasteCollection: null,
    filters: null,
    store: null,
    wines: null,
    shouldMatch: false,
  }),
  mutations: {
    setShouldMatch(state: searchStoreState, value: boolean): void {
      state.shouldMatch = value;
    },
    setFoodCollection(state: searchStoreState, value: FoodCollection): void {
      state.tasteCollection = value;
    },
    setFilters(state: searchStoreState, value: wineFilters): void {
      state.filters = value;
    },
    setStore(state: searchStoreState, value: any): void {
      state.store = value;
    },
    setWines(state: searchStoreState, value: any[]): void {
      state.wines = value;
    },
    clearSearch(state: searchStoreState): void {
      state = {
        tasteCollection: null,
        filters: null,
        store: null,
        wines: null,
        shouldMatch: false,
      };
    },
  },
  actions: {
    setShouldMatch({ commit }: { commit: Commit }, value: boolean): void {
      commit("setShouldMatch", value);
    },
    setFoodCollection({ commit }: { commit: Commit }, value: FoodCollection): void {
      commit("setFoodCollection", value);
    },
    setFilters({ commit }: { commit: Commit }, value: wineFilters): void {
      commit("setFilters", value);
    },
    setStore({ commit }: { commit: Commit }, value: any): void {
      commit("setStore", value);
    },
    setWines({ commit }: { commit: Commit }, value: any[]): void {
      commit("setWines", value);
    },
    clearSearch({ commit }: { commit: Commit }): void {
      commit("clearSearch");
    },
  },
  getters: {},
};
