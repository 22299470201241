
















import Vue from "vue";
import Txt from "./Txt.vue";
export default Vue.extend({
  components: {
    Txt,
  },
  props: ["text"],
});
