





























































import Vue from "vue";
export default Vue.extend({
  data(): { progessStep: number } {
    return {
      progessStep: 0 as number,
    };
  },
  computed: {
    progressStyle(): string {
      return "height:" + (this.progessStep / 3) * 100 + "%";
    },
  },
  mounted: function () {
    setInterval(() => {
      this.progessStep = (this.progessStep + 1) % 4;
    }, 3000);
  },
});
