






import Vue, { PropType } from "vue";
export default Vue.extend({
  props: {
    min: Number as PropType<number>,
    max: Number as PropType<number>,
    value: Number as PropType<number>,
    type: String as PropType<null | "warning">,
    orientation: String as PropType<null | "horizontal" | "vertical">,
  },
  computed: {
    barStyle: function (): string {
      if (this.orientation === "vertical") {
        return "height:" + this.percentage + "%;";
      } else {
        return "width:" + this.percentage + "%;";
      }
    },
    percentage: function (): number {
      const min: number = this.min || 0;
      const max: number = this.max || 100;
      return ((this.value - min) / (max - min)) * 100;
    },
  },
});
