
















import Vue from "vue";
import AdventureStep from "./AdventureStep.vue";
import Txt from "../Txt.vue";

export default Vue.extend({
  components: {
    AdventureStep,
    Txt,
  },
  props: {
    active: Boolean,
    disabled: Boolean,
  },
});
