<template>
  <Card class="vinny-advice" v-if="advice !== 'none'">
    <img src="@/assets/img/illustrations/vinny-scientist.svg" v-if="advice === 'start'" />
    <img src="@/assets/img/illustrations/vinny-advice-profile.svg" v-if="advice === 'profile'" />
    <Txt type="grey" v-if="advice === 'start'">
      Vous allez être surpris ! Vous pensez connaître votre vin préféré ? Mais vous ne les avez pas tous goûtés… Nos
      experts l'ont fait pour vous !
    </Txt>
    <Txt type="grey" v-if="advice === 'profile'">
      <b>Nouveau !</b> En utilisant ce bouton à côté de votre profil, vous pourrez créer d'autres profils avec de
      nouvelles réponses pour d'autres occasions !
    </Txt>
  </Card>
</template>

<script>
import Card from "@/components/Card.vue";
import Txt from "@/components/Txt.vue";
export default {
  components: {
    Card,
    Txt,
  },
  props: {
    advice: String,
  },
};
</script>

<style scoped>
.vinny-advice {
  min-height: 75px;
}

.vinny-advice img {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 60px;
}

.vinny-advice p {
  margin: 0;
  margin-left: 60px;
}

@media screen and (max-width: 500px) {
  .vinny-advice {
    margin-bottom: 0;
  }
  .vinny-advice >>> .card-content {
    padding: 10px;
    flex-direction: row;
  }
  .vinny-advice img {
    width: 40px;
  }
  .vinny-advice p {
    margin-left: 50px;
  }
}
</style>
