






















































































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import Row from "@/components/Row.vue";
import Card from "@/components/Card.vue";
import Activity from "@/components/Activity.vue";
import Separator from "@/components/Separator.vue";
import Button from "@/components/Button.vue";
import Flag from "@/components/Flag.vue";
import WineThumbnail from "@/components/WineThumbnail.vue";
import AchievementsModal from "@/components/Modals/AchievementsModal.vue";
import FavoritesModal from "@/components/Modals/FavoritesModal.vue";
import ProfileModal from "@/components/Modals/ProfileModal.vue";
import LoginModal from "@/components/Modals/LoginModal.vue";
import SignupModal from "@/components/Modals/SignupModal.vue";
import EmailSuccessModal from "@/components/Modals/EmailSuccessModal.vue";
import SignupSuccessModal from "@/components/Modals/SignupSuccessModal.vue";
import ProfileCreateSuccessModal from "@/components/Modals/ProfileCreateSuccessModal.vue";
import NeedLoginModal from "@/components/Modals/NeedLoginModal.vue";
import ProfilesModal from "@/components/Modals/ProfilesModal.vue";
import FilterStep from "@/components/Adventure/FilterStep.vue";
import StoreStep from "@/components/Adventure/StoreStep.vue";
import ProfileStep from "@/components/Adventure/ProfileStep.vue";
import WineStep from "@/components/Adventure/WineStep.vue";
import VinnyAdvice from "@/components/Adventure/VinnyAdvice.vue";
import Loader from "@/components/Loader.vue";

export default Vue.extend({
  name: "Main",
  components: {
    Activity,
    Card,
    Row,
    Separator,
    Button,
    Flag,
    WineThumbnail,
    AchievementsModal,
    FavoritesModal,
    ProfileModal,
    LoginModal,
    SignupModal,
    EmailSuccessModal,
    NeedLoginModal,
    SignupSuccessModal,
    ProfileCreateSuccessModal,
    ProfilesModal,
    FilterStep,
    StoreStep,
    ProfileStep,
    WineStep,
    VinnyAdvice,
    Loader,
  },
  data: function () {
    return {
      progress: 50,
      displayMoreWines: false,
      sessionLoading: false,
      emailLoading: false,
      winesLoading: false,
      showAchievementsModal: false,
      showProfileModal: false,
      showLoginModal: false,
      showSignupModal: false,
      showFavoritesModal: false,
      showEmailSuccessModal: false,
      showSignupSuccessModal: false,
      showProfileCreateSuccessModal: false,
      showProfilesModal: false,
      needLoginModal: false,
      eventStatus: null,
      tasteCollections: null,
    };
  },
  mounted() {
    /*[TO-DO] : Make customer dynamic */
    Vue.$sdk.Customer.get_details("58404696fedb0b040006ac91", {
      success: (customer_details) => {
        if (customer_details.is_active) {
          this.eventStatus = "active";
          Vue.$sdk.User.validate_achievement(this._id, "over_18", {
            success: () => {
              return;
            },
            error: (/*e*/) => {
              return;
            },
          });
          this.getSession();
        } else {
          this.eventStatus = "finished";
        }
      },
      error: (/*e*/) => {
        alert("Couldn't load customer infos");
      },
    });
    Vue.$sdk.User.get_taste_collections(this._id, {
      success: (result) => {
        this.tasteCollections = result;
      },
      error: (e) => {
        this.error = e;
        this.loading = false;
      },
    });
  },
  computed: {
    ...mapState("authUser", ["email", "_id", "firstname", "lastname", "avatar"]),
    ...mapState("search", ["filters", "store", "tasteCollection", "wines", "shouldMatch"]),
    winesList() {
      if (this.wines && this.wines.colors && this.filters) {
        const wines = []
          .concat(this.filters.type.White ? this.wines.colors.white : [])
          .concat(this.filters.type.Rose ? this.wines.colors.rose : [])
          .concat(this.filters.type.Sweet ? this.wines.colors.sweet : [])
          .concat(this.filters.type.Sparkling ? this.wines.colors.sparkling : [])
          .concat(this.filters.type.Red ? this.wines.colors.red : []);
        return wines.sort((a, b) => b.distance - a.distance).slice(0, this.displayMoreWines ? 7 : 3);
      }
      return null;
    },
    advice() {
      if (this.tasteCollections && this.tasteCollections.length === 1) {
        if (this.tasteCollection && this.tasteCollection.food_length > 80) {
          return "profile";
        } else {
          return "start";
        }
      }
      return "none";
    },
  },
  watch: {
    _id() {
      this.getSession();
    },
    shouldMatch() {
      if (this.shouldMatch === true && this.tasteCollection.food_length > 0) {
        this.getWines();
      }
    },
  },
  methods: {
    ...mapActions("search", [
      "setStore",
      "setFilters",
      "setWines",
      "setShouldMatch",
      "clearSearch",
      "setFoodCollection",
    ]),
    ...mapActions("notification", ["addNotification"]),
    getSession() {
      this.sessionLoading = true;
      Vue.$sdk.User.get_session(this._id, {
        success: (data) => {
          this.sessionLoading = false;
          if (data.current_session) {
            this.setStore(data.current_session.store_reference);
            this.setFilters(data.current_session.filters);
            this.setFoodCollection(data.current_session.taste_collection_reference);
            this.setWines(data.current_session.results);
            if (this.shouldMatch && this.tasteCollection && this.store && this.filters) {
              this.getWines();
            }
          } else {
            this.clearSearch();
          }
        },
        error: (e) => {
          console.error(e);
          this.sessionLoading = false;
        },
      });
    },
    getWines() {
      this.winesLoading = true;
      this.setWines([]);
      Vue.$sdk.User.get_matching({ _id: this._id }, this.store._id, this.filters, "complex", {
        success: (data) => {
          this.winesLoading = false;
          this.setWines(data);
          console.log(data);
          this.setShouldMatch(false);
          Vue.$sdk.User.validate_achievement(this._id, "first_wine", {
            success: () => {
              this.addNotification({
                icon: require("@/assets/img/icons/achievements/first-wine.svg"),
                title: "Succès dévérouillé",
                text: "Mon premier vin",
              });
            },
            error: (/*e*/) => {
              return;
            },
          });
          /*if ($scope.bullshit_finished) {
                    $location.path('/user/wines');
                    $rootScope.close_popup("computing");
                }*/
        },
        error: (/*error, code*/) => {
          this.winesLoading = false;
          /*$rootScope.close_popup("computing");*/
          //if(code === 401){
          alert("Votre licence est expirée");
          //}
        },
      });
    },
    onFavoriteClick(wine /*, value*/) {
      if (this.email) {
        if (wine.is_favorite) {
          Vue.$sdk.UserFavorite.delete(this._id, wine.wine_reference._id, {
            success: () => {
              Vue.set(wine, "is_favorite", false);
              this.addNotification({
                icon: require("@/assets/img/icons/check-circle.svg"),
                title: "Vin retiré de vos favoris",
                text: "avec succès",
              });
            },
            error: () => {
              return;
            },
          });
        } else {
          Vue.$sdk.UserFavorite.save(
            {
              user_reference: this._id,
              wine_reference: wine.wine_reference._id,
              wine_info_reference: wine.wine_info_reference,
              price: wine.price,
              distance: wine.distance,
              taste_collection_name: this.tasteCollection.name,
            },
            {
              success: () => {
                Vue.set(wine, "is_favorite", true);
                this.addNotification({
                  icon: require("@/assets/img/icons/check-circle.svg"),
                  title: "Vin ajouté aux favoris",
                  text: "avec succès",
                });
              },
              error: () => {
                return;
              },
            }
          );
        }
      } else {
        this.needLoginModal = true;
      }
    },
    sendEmail() {
      if (!this.email) {
        this.needLoginModal = true;
        return;
      }
      this.emailLoading = true;
      Vue.$sdk.User.send_matching_email(this._id, this.email, {
        success: () => {
          this.emailLoading = false;
          this.showEmailSuccessModal = true;
        },
        error: (error) => {
          //[TO-DO]
          console.error(error);
        },
      });
    },
  },
});
