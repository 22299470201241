

























import Vue from "vue";
import { mapState } from "vuex";
import Popup from "../Popup.vue";
import Txt from "../Txt.vue";
import Well from "../Well.vue";
import WineThumbnail from "../WineThumbnail.vue";
export default {
  name: "FavoritesModal",
  components: {
    Popup,
    Well,
    Txt,
    WineThumbnail,
  },
  data() {
    return {
      favorites: null,
    };
  },
  mounted() {
    this.load_favorites();
  },
  props: {
    show: Boolean,
  },
  watch: {
    show() {
      if (this.show === true) {
        this.load_favorites();
      }
    },
  },
  computed: {
    ...mapState("authUser", ["_id"]),
  },
  methods: {
    onFavoriteClick(wine) {
      this.favorites = this.favorites.filter((f) => {
        return f._id !== wine._id;
      });
      this.$emit("favoriteClicked", wine);
    },
    load_favorites() {
      this.favorites = null;
      Vue.$sdk.UserFavorite.list_by_user(this._id, {
        success: (response) => {
          this.favorites = response
            .map((w) => {
              w.is_favorite = true;
              return w;
            })
            .sort((a, b) => {
              return b.distance - a.distance;
            });
        },
      });
    },
  },
};
