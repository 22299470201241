








import Vue from "vue";
export default Vue.extend({
  props: {
    type: String,
    as: String,
    disabled: Boolean,
  },
});
