









































import Vue from "vue";
import AdventureStep from "./AdventureStep.vue";
import Button from "../Button.vue";
import Txt from "../Txt.vue";

export default Vue.extend({
  components: {
    AdventureStep,
    Button,
    Txt,
  },
  props: {
    filters: Object,
    active: Boolean,
    disabled: Boolean,
    wines: Array,
  },
});
