







import Vue from "vue";
import Popup from "../Popup.vue";
import Button from "../Button.vue";
import Txt from "../Txt.vue";
export default {
  name: "ErrorModal",
  components: {
    Popup,
    Button,
    Txt,
  },
  props: {
    show: Boolean,
    text: String,
  },
};
