import { render, staticRenderFns } from "./AchievementsModal.vue?vue&type=template&id=012caa6a&scoped=true&"
import script from "./AchievementsModal.vue?vue&type=script&lang=ts&"
export * from "./AchievementsModal.vue?vue&type=script&lang=ts&"
import style0 from "./AchievementsModal.vue?vue&type=style&index=0&id=012caa6a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012caa6a",
  null
  
)

export default component.exports