




















import Vue from "vue";
import { mapState } from "vuex";
import Card from "./Card.vue";
export default Vue.extend({
  computed: {
    ...mapState("notification", ["currentNotifications"]),
  },
  components: {
    Card,
  },
});
