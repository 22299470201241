







import Vue from "vue";
import MenuBar from "./MenuBar.vue";
import Toaster from "./Toaster.vue";

export default Vue.extend({
  components: {
    MenuBar,
    Toaster,
  },
});
