






import Vue from "vue";
import { mapState } from "vuex";
import Popup from "../Popup.vue";
export default {
  name: "PhotoModal",
  components: {
    Popup,
  },
  props: {
    show: Boolean,
    image: String,
  },
};
