import { Commit } from "vuex";

type authUserState = {
  authRequested: boolean;
  _id: string | null;
  token: string | null;
  email: string | null;
  firstname?: string;
  lastname?: string;
  avatar?: string;
};
export const authUserStore = {
  namespaced: true,
  state: (): authUserState => ({
    authRequested: false,
    _id: null,
    token: null,
    email: null,
  }),
  mutations: {
    setAuthRequested(state: authUserState, value: boolean): void {
      state.authRequested = value;
    },
    setToken(state: authUserState, value: string): void {
      state.token = value;
    },
    setId(state: authUserState, value: string): void {
      state._id = value;
    },
    setEmail(state: authUserState, value: string): void {
      state.email = value;
    },
    setFirstname(state: authUserState, value: string): void {
      state.firstname = value;
    },
    setLastname(state: authUserState, value: string): void {
      state.lastname = value;
    },
    clear(state: authUserState): void {
      state.token = null;
      state.email = null;
      state._id = null;
    },
  },
  actions: {
    setAuthRequested({ commit }: { commit: Commit }, value: boolean): void {
      commit("setAuthRequested", value);
    },
    setUser(
      { commit }: { commit: Commit },
      payload: {
        _id: string;
        email?: string;
        token?: string;
        lastname?: string;
        firstname?: string;
      }
    ): void {
      if (payload.token) {
        commit("setToken", payload.token);
      }
      commit("setId", payload._id);
      commit("setEmail", payload.email);
      commit("setFirstname", payload.firstname);
      commit("setLastname", payload.lastname);
    },
    clear({ commit }: { commit: Commit }): void {
      commit("clear");
    },
  },
  getters: {
    authRequested(state: authUserState): boolean {
      return state.authRequested;
    },
    token(state: authUserState): string | null {
      return state.token;
    },
  },
};
