import { render, staticRenderFns } from "./ProfileModal.vue?vue&type=template&id=47ee8eb4&scoped=true&"
import script from "./ProfileModal.vue?vue&type=script&lang=ts&"
export * from "./ProfileModal.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileModal.vue?vue&type=style&index=0&id=47ee8eb4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ee8eb4",
  null
  
)

export default component.exports