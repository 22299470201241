

















import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      internalValue: "",
    };
  },
  props: {
    value: String,
    error: String,
    label: String,
    placeholder: String,
    type: String,
    autocomplete: String,
    disabled: Boolean,
  },
  mounted() {
    this.internalValue = this.value;
  },
  computed: {
    inputName() {
      return (this.label || "")
        .toLowerCase()
        .normalize("NFD")
        .replace(/[^a-z]/gm, "");
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.internalValue);
    },
  },
});
