






















import Vue from "vue";
import { mapActions } from "vuex";
import Popup from "../Popup.vue";
import Input from "../Input.vue";
import Button from "../Button.vue";
import Alert from "../Alert.vue";
export default {
  name: "LoginModal",
  components: {
    Popup,
    Button,
    Input,
    Alert,
  },
  data() {
    return {
      email: "",
      emailError: null,
      password: "",
      passwordError: null,
      error: null,
      loading: false,
    };
  },
  props: {
    show: Boolean,
  },
  methods: {
    ...mapActions("notification", ["addNotification"]),
    ...mapActions("authUser", ["setUser"]),
    login(): void {
      this.error = null;
      this.emailError = null;
      this.passwordError = null;

      if (this.email.length === 0) {
        this.emailError = "Veuillez indiquer votre e-mail";
      } else if (this.password.length === 0) {
        this.passwordError = "Veuillez indiquer votre mot de passe";
      } else {
        this.loading = true;
        Vue.$sdk.Auth.login(
          { email: this.email, password: this.password },
          {
            success: ({ email, token, _id, firstname, lastname }) => {
              this.setUser({ email, token, _id, firstname, lastname });
              this.loading = false;
              this.addNotification({
                icon: require("@/assets/img/icons/check-circle.svg"),
                title: "Connecté(e)",
                text: "avec succès",
              });
              this.$emit("close");
            },
            error: (e) => {
              if (e.response) {
                switch (e.response.status) {
                  case 404: {
                    this.error = "Cet utilisateur n'existe pas";
                    break;
                  }
                  case 500: {
                    this.error = "Erreur serveur, veuillez réessayer";
                    break;
                  }
                  default: {
                    this.error = "Erreur inconnue, merci de réessayer plus tard";
                  }
                }
              } else {
                this.error = "Erreur inconnue, merci de réessayer plus tard";
              }
              this.loading = false;
            },
          }
        );
      }
    },
  },
  watch: {
    email() {
      this.emailError = null;
      this.error = null;
    },
    password() {
      this.passwordError = null;
      this.error = null;
    },
  },
};
