














import Vue from "vue";
import { mapActions } from "vuex";
import { bootstrap } from "vue-gtag";
import Popup from "../Popup.vue";
import Button from "../Button.vue";
export default {
  name: "AppsModal",
  components: {
    Popup,
    Button,
  },
  props: {
    show: Boolean,
  },
};
