import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Over18 from "../views/Over18.vue";
import Quiz from "../views/Quiz.vue";
import Main from "../views/Main.vue";
import Store from "../views/Store.vue";
import Wine from "../views/Wine.vue";
import Filters from "../views/Filters.vue";
import LegalMentions from "../views/LegalMentions.vue";

import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Over18",
    component: Over18,
    meta: { requiresLogin: false },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresLogin: false },
  },
  {
    path: "/quiz",
    name: "Quiz",
    component: Quiz,
    meta: { requiresLogin: true },
  },
  {
    path: "/main",
    name: "Main",
    component: Main,
    meta: { requiresLogin: true },
  },
  {
    path: "/store",
    name: "Store",
    component: Store,
    meta: { requiresLogin: true },
  },
  {
    path: "/wine",
    name: "Wine",
    component: Wine,
    meta: { requiresLogin: true },
  },
  {
    path: "/filters",
    name: "Filters",
    component: Filters,
    meta: { requiresLogin: true },
  },
  {
    path: "/legal-mentions",
    name: "LegalMentions",
    component: LegalMentions,
  },
  { path: "*", redirect: "/" },
  /*{
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ //"../views/About.vue"),
  //},
];

const router = new VueRouter({
  mode: process.env.VUE_APP_HISTORY_MODE,
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  //If requires is specified (true or false), access is restricted, if not specified, route is always accessible
  if (to.matched.some((record) => record.meta.requiresLogin === true) && !store.state.authUser.token) {
    next("/");
  } else if (to.matched.some((record) => record.meta.requiresLogin === false) && store.state.authUser.token) {
    next("/main");
  } else {
    next();
  }
});

export default router;
