


















import Vue from "vue";
import { mapState } from "vuex";
import Popup from "../Popup.vue";
import Well from "../Well.vue";
import Txt from "../Txt.vue";
export default {
  name: "AchievementsModal",
  components: {
    Popup,
    Well,
    Txt,
  },
  data() {
    return {
      userAchievements: {},
    };
  },
  mounted() {
    this.load_achievements();
  },
  props: {
    show: Boolean,
  },
  watch: {
    show() {
      if (this.show === true) {
        this.load_achievements();
      }
    },
  },
  computed: {
    ...mapState("authUser", ["_id"]),
    achievements() {
      return [
        {
          key: "over_18",
          title: "J'ai plus de 18 ans",
          icon: require("../../assets/img/icons/achievements/age-18.svg"),
        },
        {
          key: "profile_30_percents",
          title: "Profil complété (30%)",
          icon: require("../../assets/img/icons/achievements/food-1.svg"),
        },
        {
          key: "profile_60_percents",
          title: "Profil complété (60%)",
          icon: require("../../assets/img/icons/achievements/food-2.svg"),
        },
        {
          key: "profile_100_percents",
          title: "Profil complété (100%)",
          icon: require("../../assets/img/icons/achievements/food-3.svg"),
        },
        {
          key: "first_wine",
          title: "Mon premier vin",
          icon: require("../../assets/img/icons/achievements/first-wine.svg"),
        },
      ];
    },
  },
  methods: {
    load_achievements() {
      Vue.$sdk.User.get_achievements(this._id, {
        success: (data) => {
          this.userAchievements = data;
        },
        error: (e) => {
          console.error(e);
        },
      });
    },
  },
};
