






















import Vue from "vue";
import Card from "../Card.vue";

export default Vue.extend({
  components: {
    Card,
  },
  props: {
    type: String,
    disabled: Boolean,
  },
});
