











import Vue from "vue";
import Popup from "../Popup.vue";
import Separator from "../Separator.vue";
import Txt from "../Txt.vue";
export default {
  name: "SignupSuccessModal",
  components: {
    Popup,
    Separator,
    Txt,
  },
  props: {
    show: Boolean,
  },
};
