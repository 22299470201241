








import Vue from "vue";
export default Vue.extend({
  data: function () {
    return {
      inputId: null,
      internalValue: true,
    };
  },
  mounted() {
    this.inputId = "switch-" + (Math.random() + "").replace(".", "");
  },
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: Boolean,
    label: String,
  },
  methods: {},
});
