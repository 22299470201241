















import Vue from "vue";
export default Vue.extend({
  methods: {
    open(url): void {
      open(url, "_blank");
    },
  },
});
