



























import Vue from "vue";
import AdventureStep from "./AdventureStep.vue";
import Button from "../Button.vue";
import Txt from "../Txt.vue";
import ProgressBar from "../ProgressBar.vue";

export default Vue.extend({
  components: {
    AdventureStep,
    Button,
    Txt,
    ProgressBar,
  },
  computed: {
    percentage() {
      return this.tasteCollection
        ? Math.min(Math.max((this.tasteCollection.food_length / 89 || 0) * 100, 0), 100).toFixed(0)
        : 0;
    },
    progressColor() {
      if (this.percentage < 40) {
        return "danger";
      } else if (this.percentage < 80) {
        return "warning";
      } else {
        return "important";
      }
    },
  },
  props: {
    tasteCollection: Object,
    foodTastes: Object,
    active: Boolean,
  },
});
