import Vue from "vue";
import App from "./App.vue";
import SdkPlugin from "./plugins/SdkPlugin";
import VModal from "vue-js-modal";
import VueGtag from "vue-gtag";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueWordCloud from "vuewordcloud";

Vue.config.productionTip = false;
Vue.use(VModal);
Vue.use(SdkPlugin);

Vue.use(
  VueGtag,
  {
    config: {
      id: "UA-141004337-1",
      cookie_domain: "quelvinetesvous.fr",
    },
    bootstrap: false,
    params: {
      //send_page_view: false
    },
  },
  router
);
/*
import FastClick from "fastclick";

window.addEventListener(
  "load",
  () => {
    FastClick.attach(document.body);
  },
  false
);
*/
/*AnalyticsProvider.enterDebugMode(false);
    AnalyticsProvider.startOffline(true);
    //AnalyticsProvider.trackPrefix("native");
    AnalyticsProvider.setDomainName('auto');
    AnalyticsProvider.setHybridMobileSupport(true);
    AnalyticsProvider.ignoreFirstPageLoad(true);
    AnalyticsProvider.setAccount({
        tracker: 'UA-141004337-1',
        fields: {
            cookieDomain: 'quelvinetesvous.fr'
        }
    });*/

Vue.$sdk.init("prod", {
  on_auth_user_changed: function (auth_user) {
    store.dispatch("authUser/setAuthRequested", true);
    if (auth_user) {
      store.dispatch("authUser/setUser", {
        _id: auth_user._id,
        token: auth_user.token,
        email: auth_user.email,
        firstname: auth_user.firstname,
        lastname: auth_user.lastname,
      });
    }
  },
});

Vue.component(VueWordCloud.name, VueWordCloud);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
