













import Vue from "vue";
import { mapState, mapActions } from "vuex";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import CookieModal from "./components/Modals/CookieModal.vue";
import AppsModal from "./components/Modals/AppsModal.vue";

export default Vue.extend({
  components: {
    Header,
    Footer,
    CookieModal,
    AppsModal,
  },
  data() {
    return {
      showCookieModal: false,
      showAppsModal: false,
    };
  },
  mounted: function () {
    this.detectTheme();
    if (this.isMobile && !this.isNativeVersion && this.theme === "u") {
      this.showAppsModal = true;
    }
  },
  computed: {
    ...mapState("authUser", ["authRequested"]),
    ...mapState("meta", ["theme", "isNativeVersion", "isMobile"]),
  },
  methods: {
    ...mapActions("meta", ["setTheme"]),
    detectTheme() {
      var host = location.hostname;
      /*if(!host){ 
          $rootScope.is_native_version = true;
          return 'u';
      } else */
      if (
        host.indexOf("d2jppkwagmam1i.cloudfront.net") > -1 ||
        host.indexOf("quelvinetesvous.fr") > -1 ||
        host.indexOf("qvev.fr") > -1
      ) {
        this.setTheme("u");
      } else {
        this.setTheme(this.$route.query.theme);
      }
    },
  },
});
