import Vue from "vue";
import Vuex from "vuex";
import { authUserStore } from "./authUserStore";
import { searchStore } from "./searchStore";
import { notificationStore } from "./notificationStore";
import { metaStore } from "./metaStore";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authUser: authUserStore,
    search: searchStore,
    notification: notificationStore,
    meta: metaStore,
  },
});
