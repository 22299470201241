import { render, staticRenderFns } from "./FoodQuizStartedModal.vue?vue&type=template&id=7bb65bf3&scoped=true&"
import script from "./FoodQuizStartedModal.vue?vue&type=script&lang=ts&"
export * from "./FoodQuizStartedModal.vue?vue&type=script&lang=ts&"
import style0 from "./FoodQuizStartedModal.vue?vue&type=style&index=0&id=7bb65bf3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb65bf3",
  null
  
)

export default component.exports