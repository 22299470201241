import { Commit } from "vuex";

type notification = {
  title: string;
  text: string;
  icon?: string;
};

type notificationStoreState = {
  currentNotifications: notification[];
  oldNotifications: notification[];
};

export const notificationStore = {
  namespaced: true,
  state: (): notificationStoreState => ({
    currentNotifications: [],
    oldNotifications: [],
  }),
  mutations: {
    addNotification(
      state: notificationStoreState,
      content: notification
    ): void {
      const currentNotifications = state.currentNotifications;
      currentNotifications.push(content);
      state = { ...state, currentNotifications };
    },
    archiveNotification(
      state: notificationStoreState,
      content: notification
    ): void {
      const currentNotifications = state.currentNotifications;
      currentNotifications.splice(currentNotifications.indexOf(content), 1);
      const oldNotifications = state.oldNotifications;
      oldNotifications.push(content);
      state = { ...state, currentNotifications, oldNotifications };
    },
  },
  actions: {
    addNotification(
      { commit }: { commit: Commit },
      content: notification
    ): void {
      commit("addNotification", content);
      setTimeout(() => {
        commit("archiveNotification", content);
      }, 3000);
    },
  },
  getters: {},
};
