























import Txt from "./Txt.vue";
export default {
  name: "Popup",
  components: {
    Txt,
  },
  props: {
    name: String,
    show: Boolean,
    title: String,
    maxWidth: Number,
  },
  mounted(): void {
    this.toggleDisplay();
  },
  methods: {
    toggleDisplay(): void {
      if (this.show) {
        this.$modal.show(this.name);
      } else {
        this.$modal.hide(this.name);
      }
    },
  },
  watch: {
    show(): void {
      this.toggleDisplay();
    },
  },
};
