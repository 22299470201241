






























import Vue from "vue";
import { mapActions, mapState } from "vuex";
import Popup from "../Popup.vue";
import Input from "../Input.vue";
import Button from "../Button.vue";
import SelectBox from "../SelectBox.vue";
import Alert from "../Alert.vue";
import Separator from "../Separator.vue";
import Txt from "../Txt.vue";
export default {
  name: "ProfilesModal",
  components: {
    Popup,
    Button,
    Input,
    Alert,
    SelectBox,
    Separator,
    Txt,
  },
  data() {
    return {
      newFoodCollection: {
        show: false,
        loading: false,
        name: "",
        nameError: null,
      },
      selectedFoodCollection: null,
      tasteCollections: null,
      loading: false,
      error: null,
    };
  },
  props: {
    show: Boolean,
  },
  computed: {
    ...mapState("authUser", ["_id"]),
    collections() {
      return this.tasteCollections
        ? this.tasteCollections.map((c) => {
            c.text = "Progression: " + this.percentage(c) + "%";
            return c;
          })
        : null;
    },
  },
  mounted() {
    Vue.$sdk.User.get_taste_collections(this._id, {
      success: (result) => {
        this.tasteCollections = result;
      },
      error: (e) => {
        this.error = e;
        this.loading = false;
      },
    });
  },
  watch: {
    selectedFoodCollection(item) {
      this.setCollection(item);
    },
  },
  methods: {
    ...mapActions("search", ["setFoodCollection", "setShouldMatch"]),
    setCollection(collection) {
      Vue.$sdk.User.update_session(
        this._id,
        { taste_collection_reference: collection._id || null },
        {
          success: () => {
            this.setFoodCollection(collection);
            this.setShouldMatch(true);
            this.$emit("close");
          },
          error: (error) => {
            //[TO-DO]
            alert(error);
          },
        }
      );
    },
    percentage(collection) {
      return collection ? Math.min(Math.max((collection.food_length / 89 || 0) * 100, 0), 100).toFixed(0) : 0;
    },
    create(): void {
      this.error = null;
      this.newFoodCollection.nameError = null;

      if (this.newFoodCollection.name.length === 0) {
        this.newFoodCollection.nameError = "Veuillez indiquer un nom de profil";
      } else {
        this.newFoodCollection.loading = true;
        Vue.$sdk.User.create_taste_collection(
          this._id,
          { name: this.newFoodCollection.name },
          {
            success: (result) => {
              this.loading = false;
              this.tasteCollections.push(result);
              if (this.tasteCollections.length === 2) {
                this.$emit("firstCustomProfileCreated");
              }
              this.setCollection(result);
            },
            error: (e) => {
              console.error(e);
              if (e.response) {
                switch (e.response.status) {
                  case 404: {
                    this.error = "Cet utilisateur n'existe pas";
                    break;
                  }
                  case 500: {
                    this.error = "Erreur serveur, veuillez réessayer";
                    break;
                  }
                  default: {
                    this.error = "Erreur inconnue, merci de réessayer plus tard";
                  }
                }
              } else {
                this.error = "Erreur inconnue, merci de réessayer plus tard";
              }
              this.loading = false;
            },
          }
        );
      }
    },
  },
};
