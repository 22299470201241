









import Vue from "vue";
export default Vue.extend({
  props: {
    type: String,
    size: String,
    iconRight: String,
    iconLeft: String,
    disabled: Boolean,
    loading: Boolean,
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
});
