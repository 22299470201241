

























































































import Vue from "vue";
import { mapState } from "vuex";
import Activity from "@/components/Activity.vue";
import Button from "@/components/Button.vue";
import Card from "@/components/Card.vue";
import Row from "@/components/Row.vue";
import Separator from "@/components/Separator.vue";
import Txt from "@/components/Txt.vue";
import PhotoModal from "@/components/Modals/PhotoModal.vue";
import JsBarcode from "jsbarcode";
export default Vue.extend({
  name: "Wine",
  components: {
    Activity,
    Button,
    Card,
    Row,
    Separator,
    Txt,
    PhotoModal,
  },
  data: function () {
    return {
      wine: null,
      showPhotoModal: false,
    };
  },
  computed: {
    ...mapState("search", ["store"]),
    wineName() {
      //return `${this.wineColor} - ${this.data.wine_reference.winery.toLowerCase()}`;
      if (this.wine.wine_reference.cuvee) {
        return this.wine.wine_reference.cuvee.toLowerCase();
      } else if (this.wine.wine_reference.winery) {
        return this.wine.wine_reference.winery.toLowerCase();
      } else if (this.wine.wine_reference.name) {
        return this.wine.wine_reference.name.toLowerCase();
      } else {
        return "Vin inconnu";
      }
    },
    wineSubName() {
      return this.wine.wine_reference.denomination.toLowerCase();
    },
    wineColor() {
      switch (this.wine.wine_reference.color) {
        case "red": {
          return "Rouge";
        }
        case "white": {
          return "Blanc";
        }
        case "rose": {
          return "Rosé";
        }
        default: {
          return this.wine.color;
        }
      }
    },
    wineMainType() {
      switch (this.wine.wine_reference.main_type) {
        case "still": {
          return "Tranquille";
        }
        case "sparkling": {
          return "Effervescent";
        }
        case "sweet": {
          return "Moelleux";
        }
        default: {
          return this.wine.wine_reference.main_type;
        }
      }
    },
    words() {
      if (this.wine.wine_reference.words) {
        return this.wine.wine_reference.words;
      } else if (this.wine.wine_reference.description) {
        return this.wine.wine_reference.description.split(" ").map((w) => {
          return [w.replace(",", ""), Math.random() * 10];
        });
      } else {
        return [];
      }
    },
  },
  mounted() {
    Vue.$sdk.Store.get_wine_details(this.$route.query.storeId, this.$route.query.wineId, {
      success: (data) => {
        this.wine = data;
        if (this.wine.wine_reference.gencod) {
          JsBarcode("#barcode", this.wine.wine_reference.gencod);
        }
      },
      error: () => {
        return;
      },
    });
  },
  methods: {
    open(url) {
      window.open(url);
    },
  },
});
