












import Vue from "vue";
import { mapActions, mapState } from "vuex";
import Popup from "../Popup.vue";
import Button from "../Button.vue";
import Separator from "../Separator.vue";
import Input from "../Input.vue";
import Txt from "../Txt.vue";
export default {
  name: "ProfileModal",
  components: {
    Popup,
    Button,
    Separator,
    Input,
    Txt,
  },
  props: {
    show: Boolean,
  },
  computed: {
    ...mapState("authUser", ["firstname", "lastname", "email"]),
  },
  methods: {
    ...mapActions("authUser", ["clear"]),
    ...mapActions("notification", ["addNotification"]),
    logout(): void {
      this.clear();
      Vue.$sdk.Auth.remove_auth_user();
      this.$emit("close");
      this.$router.push("/home");
      this.addNotification({
        icon: require("@/assets/img/icons/check-circle.svg"),
        title: "Déconnecté(e)",
        text: "avec succès",
      });
    },
  },
};
