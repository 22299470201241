


























import Vue from "vue";
import { mapActions } from "vuex";
import { bootstrap } from "vue-gtag";
import Popup from "../Popup.vue";
import Txt from "../Txt.vue";
import ToggleSwitch from "../ToggleSwitch.vue";
import Separator from "../Separator.vue";
export default {
  name: "CookieModal",
  components: {
    Popup,
    Txt,
    ToggleSwitch,
    Separator,
  },
  data() {
    return {
      cookieConsent: false,
    };
  },
  props: {
    show: Boolean,
  },
  mounted() {
    if (localStorage) {
      const consent = localStorage.getItem("cookie_consent");
      if (consent === null || consent === "true") {
        this.cookieConsent = true;
      }
      if (this.cookieConsent) {
        this.enable_analytics();
      }
    }
  },
  watch: {
    cookieConsent() {
      if (this.cookieConsent) {
        this.enable_analytics();
      } else {
        this.disable_analytics();
      }
      localStorage.setItem("cookie_consent", this.cookieConsent + "");
    },
  },
  methods: {
    enable_analytics() {
      bootstrap().then((gtag) => {
        // all done!
      });
    },
    disable_analytics() {
      localStorage.setItem("cookie_consent", "false");
      location.reload(true);
    },
  },
};
